import { EXTENSION_ID, MESSAGE_TYPES, SERVICES_HOSTNAME } from "cfg/endpoints";
import { apiKeyStorageKey, getLocalStorage, setLocalStorage } from "cfg/localstorage";

export type AuthorizationInfo = {
  apiKey: string | null
  services: string
  has_extension?: boolean
}

export async function getAuthorizationViaChromeExtension() {
    try {
      if (chrome && chrome.runtime) {
        try {
        const information = await chrome.runtime.sendMessage(EXTENSION_ID, {
          'type': MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_REQUEST_EXTENSION_API_KEY_AND_EXTENSION_INFO,
        })
        return {apiKey: information['apiKey'], services: information['forceServicesHostname'] ?? SERVICES_HOSTNAME }

      } catch (e) {
        return null
      }
    }
    } catch (e) {
      return null
    }
    return null
}

export function getAuthorizationViaLocalStorage() {
    return getLocalStorage(apiKeyStorageKey)
}

export function updateAuthorizationApiKey(value: string) {
  setLocalStorage(apiKeyStorageKey, value)
}

export function getAuthorizationViaWindowLocation() {
  return (new URLSearchParams(window.location.search)).get('apiKey')
}

export async function getApiKeyInfo(): Promise<AuthorizationInfo | null> {
    const window = getAuthorizationViaWindowLocation()
    if (window) return { apiKey: window, services: SERVICES_HOSTNAME }
    const chromeExtensionApiKey = await getAuthorizationViaChromeExtension()
    if (chromeExtensionApiKey && chromeExtensionApiKey['apiKey']) return {...chromeExtensionApiKey, has_extension: true }
    const storage = getAuthorizationViaLocalStorage()
    if (storage) return {apiKey: storage, services: SERVICES_HOSTNAME }
    let hasChrome = false
    try {
      hasChrome = chrome != null && chrome.runtime != null
    } catch (e) {
      hasChrome = false
    }
    return {apiKey: null, services: SERVICES_HOSTNAME, has_extension: hasChrome }
}